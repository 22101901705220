//libraries
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import axios from 'axios';

//js
import { getDevId } from '../assets/sec/getDevId';


export function Landing({deviceType})
{
      const navigate=useNavigate();


      useEffect(()=>
      {
            //sending usageStats
            (async function()
            {
                  axios.post('/sendUsageStats', { devId: await getDevId() });
            })();


            //showing logo animation
            if(deviceType==='webview') var animTime=0;
            else var animTime=2000;

            setTimeout(()=>
            {
                  return navigate('/home');
            },
            animTime);


            //changing background color
            document.querySelector('body').style.backgroundColor='white';
            return ()=> document.querySelector('body').style.backgroundColor='rgb(250, 250, 250)';
      },
      []);


      if(deviceType!='webview') 
      return<>
            <div className="animate__animated animate__flip     text-center mx-3 mx-sm-0" style={{marginTop: '16rem'}}>
                  <img className="img-fluid" src={require('../assets/logo.jpg')} alt="MyXAcademy.com" />
            </div>
      </>;
};

//libraries
import ReCAPTCHA from 'react-google-recaptcha';


export function CaptchaBox({setIsCaptchaVisible, setCaptchaToken, handleDone})
{
      function doneCaptcha()
      {
            setIsCaptchaVisible(false);
            handleDone();
            setCaptchaToken('');
      };


      return<>
            <div className="d-flex justify-content-center">
                  <div className="animate__animated animate__fadeInDown     shadow position-fixed rounded" style={{top: '24%', zIndex: '1', backgroundColor: 'white', border: '2px solid black'}}>
                                                
                        <div>
                              <div className="mt-2 p-3">
                                    <ReCAPTCHA
                                          sitekey='6Lcix3ceAAAAAAyTLaczjuEoGTUHR6o2OWFuW-MT'
                                          onChange={ (value)=> setCaptchaToken(value) }
                                    />

                                    <button onClick={doneCaptcha} className="btn btn-primary mt-2">Done</button>
                              </div>
                        </div>

                  </div>
            </div>
      </>
};

// Initialize the agent at application startup (i.e. add this script at the top)
//no need to add fingerprintJs before adding this script

import FingerprintJS from '@fingerprintjs/fingerprintjs';


export async function getDevId()
{
      const fp=await FingerprintJS.load();
      const {visitorId}=await fp.get();
      return visitorId;
};

//libraries
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import Ripples from "react-ripples";

//js
import {formatDateTime} from '../assets/formatDateTime.js';
import { env } from "../assets/env.js";

//css
import '../styles/messages.css';


export function Messages({user, chatBoxes, socket, renderActiveStatus, dateNow})
{
      const [currChatBoxes, setCurrChatBoxes]=useState(false);
      const [typingUserIdArr, setTypingUserIdArr]=useState([]);
      const navigate=useNavigate();


      useEffect(()=>
      {
            if(chatBoxes===false || chatBoxes.length===0) return;

            //sorting currChatBoxes based on dati property of last element of chats array of chatBoxes array
            let chatBoxesClone=JSON.parse(JSON.stringify(chatBoxes));

            chatBoxesClone.sort((a, b)=>
            {
                  return new Date(b.chats[b.chats.length-1].dati) - new Date(a.chats[a.chats.length-1].dati);
            });

            setCurrChatBoxes(chatBoxesClone);
      },
      [chatBoxes]);


      useEffect(()=>
	{
		if(socket===false) return;

		socket.on('receiveTypingIndicator_Messages', (data)=>
		{
                  setTypingUserIdArr((typingUserIdArr)=> [...typingUserIdArr, data.id_users]);

                  setTimeout(()=>
                  {
                        setTypingUserIdArr([]);
                  },
                  5000);
		});

            return ()=> socket.off('receiveTypingIndicator_Messages');
	},
	[socket]);


      function getLastChat(chatBox)
      {
            return chatBox.chats[chatBox.chats.length-1];
      };


      function formatChatBody(chatBox, i)
      {
            let lastChat=getLastChat(chatBox)

            if(lastChat.shouldHide) return;

            let body=lastChat.body;

            let dealText=getDealText(user.id_users, chatBox.inboxUser.id_users, chatBox.inboxUser.username, body);

            if(dealText!==false)
            {
                  return <span style={{backgroundColor: 'whitesmoke'}}>{ dealText.slice(0, 30) }</span>;
            }

            else if(body.slice(0, 12)==='/chatImages/')
            {
                  let chatBody=[];
                  chatBody.push(<i key={crypto.randomUUID} className={'fa-image' + (lastChat.isSeen===0 && lastChat.fromId!==user.id_users ? ' fa-regular' : ' fa-light') } />);
                  chatBody.push(' Image');
                  
                  return chatBody;
            }

            else 
            {
                  return body.slice(0, 30);
            };
      };


      function getDealText(selfId, theirId, theirUsername, body)
      {
            let dealInfoArr=body.split('_');

            let dealType=dealInfoArr[1];
            let dealFromId=Number(dealInfoArr[2]);
            let dealToId=Number(dealInfoArr[3]);
            let dealPayment=Number(dealInfoArr[4]);
            
            if( ! dealFromId || ! Number.isInteger(dealFromId) ) return false;
            if( ! dealToId || ! Number.isInteger(dealToId) ) return false;
            if( ! dealPayment || ! Number.isInteger(dealPayment) ) return false;

            theirUsername=theirUsername.split(' ')[0];


            if(dealType==='dealRequest')
            {
                  if(dealFromId===selfId && dealToId===theirId) 
                  {
                        return `You requested to help ${theirUsername} for ${dealPayment} coins`;
                  };
                  
                  if(dealFromId===theirId && dealToId===selfId)
                  {
                        return `${theirUsername} wants to help you for ${dealPayment} coins`;
                  };
            };


            if(dealType==='cancelReceivedDeal')
            {
                  if(dealFromId===selfId && dealToId===theirId) 
                  {
                        return `You cancelled ${theirUsername}'\s deal of ${dealPayment} coins`;
                  };
                  
                  if(dealFromId===theirId && dealToId===selfId)
                  {
                        return `${theirUsername} cancelled your deal of ${dealPayment} coins`;
                  };
            };


            if(dealType==='cancelSentDeal')
            {
                  if(dealFromId===selfId && dealToId===theirId) 
                  {
                        return `You cancelled your deal of ${dealPayment} coins`;
                  };
                  
                  if(dealFromId===theirId && dealToId===selfId)
                  {
                        return `${theirUsername} cancelled their deal of ${dealPayment} coins`;
                  };
            };


            if(dealType==='acceptDeal')
            {
                  if(dealFromId===selfId && dealToId===theirId) 
                  {
                        return `You accepted ${theirUsername}\'s deal of ${dealPayment} coins`;
                  };
                  
                  if(dealFromId===theirId && dealToId===selfId)
                  {
                        return `${theirUsername} accepted your deal of ${dealPayment} coins`;
                  };
            };


            if(dealType==='finishReceivedDeal')
            {
                  if(dealFromId===selfId && dealToId===theirId) 
                  {
                        return `You finished ${theirUsername}\'s deal of ${dealPayment} coins. Please give a review (click on their name at the top).`;
                  };
                  
                  if(dealFromId===theirId && dealToId===selfId)
                  {
                        return `${theirUsername} finished your deal of ${dealPayment} coins`;
                  };
            };


            if(dealType==='finishSentDeal')
            {
                  if(dealFromId===selfId && dealToId===theirId) 
                  {
                        return `You finshed your deal of ${dealPayment} coins`;
                  };
                  
                  if(dealFromId===theirId && dealToId===selfId)
                  {
                        return `${theirUsername} finished their deal of ${dealPayment} coins. Please give a review (click on their name at the top).`;
                  };
            };

            
            return false;
      };


      return<>
            <div className="container-fluid px-0 pb-5 mb-5">

                  <h2 className="text-center my-4">Messages</h2>

                  { (user===false) && <h4 className="text-center text-muted" style={{marginTop: '8rem'}}>Please login to view messages</h4> }

                  { (currChatBoxes!==false) && currChatBoxes.map(function(chatBox, i){
                        return(
                              <div key={i} className="d-flex justify-content-center">
                                    <Ripples className="mb-2 rounded-lg">
                                          <div onClick={()=>navigate(`/inbox?ui=${chatBox.inboxUser.id_users}`)} className="messageCard table bg-white d-flex flex-column justify-content-center pl-2 pr-1 mb-0" style={{width: '600px', height: '75px'}}>
                                                <div className="d-flex">

                                                      <img className="rounded-circle" src={`${env.backendBaseUrl}/profileImages/${chatBox.inboxUser.id_users}.jpg?${dateNow}`} onError={e=>e.target.src=require('../assets/defaultImage.jpg')} style={{height: '55px', width: '55px', objectFit: 'cover'}} />

                                                      <div className={"w-100 ml-2 mr-1" + (getLastChat(chatBox).toId===user.id_users && getLastChat(chatBox).isSeen===0 ? ' font-weight-bold' : '' ) }>
                                                            <div className="d-flex justify-content-between mb-1">
                                                                  <div className="d-flex">
                                                                        <div className="mr-1">{chatBox.inboxUser.username!==undefined && chatBox.inboxUser.username.slice(0, 20)}</div>
                                                                        <div>{renderActiveStatus(chatBox.inboxUser.id_users)}</div>
                                                                  </div>
                                                                  <div className="text-muted text-nowrap" style={{fontSize: 'small'}}>{formatDateTime(getLastChat(chatBox).dati)}</div>
                                                            </div>
                                                            <div className='text-muted'>
                                                                  { typingUserIdArr.includes(chatBox.inboxUser.id_users) ?
                                                                        <span className="font-weight-bold text-primary">Typing...</span>
                                                                  :
                                                                        <span>
                                                                              { (getLastChat(chatBox).fromId===user.id_users) ? 'Me: ' : '' }
                                                                              { formatChatBody(chatBox, i) }
                                                                        </span>
                                                                  }
                                                            </div>
                                                      </div>

                                                </div>
                                          </div>
                                    </Ripples>
                              </div>
                        );
                  })}
            </div>
      </>
};

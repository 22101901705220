//libraries
import { useEffect, useState } from "react"
import axios from "axios";
import { useNavigate } from "react-router-dom";

//js
import { env } from "../assets/env";


export function EditProfile({user, setUser, profileImgHash, setProfileImgHash, updateOffers})
{
      const [username, setUsername]=useState('');
      const [description, setDescription]=useState('');
      const [imageFile, setImageFile]=useState(false);
      const navigate=useNavigate();


      useEffect(()=>
      {
            if(user===false) return;
            
            setUsername(user.username);
            setDescription(user.profile_description);
      },
      [user]);


      useEffect(()=>
      {
            if(imageFile===false) return;

            let confirmImageUpload=window.confirm(`Do you want to set this image as profile picture? Filename: ${imageFile.name}`);
            if(confirmImageUpload===false) return setImageFile(false);

            const formData=new FormData();
            formData.append('file', imageFile);

            (async function()
            {
                  let res=await axios.post('/uploadProfileImage', formData);

                  if(res.data.isUploaded===false) alert(res.data.msg);
                  else alert('Profile picture updated');

                  //don't use success condition here to setImagHash because uploading image file bigger than allowed limit will delete the image by multer
                  //so image hash must be updated even if 'isUploaded' is failed
                  setProfileImgHash(Date.now());
            })();

            setImageFile(false);
      },
      [imageFile]);


      async function saveNewUsername()
      {
            let res=await axios.post('/saveNewUsername', {username});

            alert(res.data.msg);

            if(res.data.isUpdated)
            {
                  user.username=username;
                  setUser(user);

                  updateOffers();
            };
      };


      async function saveNewProfileDescription()
      {
            let res=await axios.post('/saveNewProfileDescription', {description});

            alert(res.data.msg);

            if(res.data.isUpdated)
            {
                  user.profile_description=description;
                  setUser(user);
            };
      };


      async function removeProfileImage()
      {
            let res=await axios.post('/removeProfileImage', {});

            if(res.data.isRemoved===false) alert(res.data.msg);
            else alert('Profile picture removed');

            if(res.data.isRemoved) setProfileImgHash(Date.now());
      };

      
      return <>
            <div className="container-fluid p-2 mb-5">

                  <div>
                        <h2 className="text-center mt-4 mb-5">Edit Profile</h2>

                        <div className="d-flex justify-content-center">
                              <div className="table" style={{maxWidth: '500px'}}>

                                    <div className="mb-4 bg-white px-3 py-2 rounded">
                                          <div className="mb-1">Username:</div>
                                          <input onChange={(e)=>setUsername(e.target.value)} value={username} type="text" className="form-control" />
                                          <div className="d-flex justify-content-end mt-1">
                                                <button className="rounded-pill btn btn-success py-0 px-2" onClick={saveNewUsername}>Save username</button>
                                          </div>
                                    </div>

                                    <div className="mb-4 bg-white px-3 py-2 rounded">
                                          <div className="mb-1">My description:</div>
                                          <textarea onChange={(e)=>setDescription(e.target.value)} value={description} rows="7" className="form-control" />
                                          <div className="d-flex justify-content-end mt-1">
                                                <button className="rounded-pill btn btn-success py-0 px-2" onClick={saveNewProfileDescription}>Save description</button>
                                          </div>
                                    </div>

                                    <div className="bg-white px-3 py-3 rounded">
                                          <div className="d-flex align-items-center mt-2">
                                                <div className="mr-2">Profile picture:</div>
                                                <img className="rounded-circle" src={`${env.backendBaseUrl}/profileImages/${user.id_users}.jpg?${profileImgHash}`} onError={(e)=>e.target.src=require('../assets/defaultImage.jpg')} style={{height: '60px', width: '60px', objectFit: 'cover'}} />
                                          </div>
                                          <div className="d-flex justify-content-around mt-5 mb-3">
                                                <button className="rounded-pill btn btn-danger py-0 px-2" onClick={removeProfileImage}>Remove image</button>
                                                <button className="rounded-pill btn btn-success py-0 px-2" onClick={()=>document.getElementById('inputProfileImageId').click()}>Upload new image</button>
                                                <input hidden id="inputProfileImageId" onChange={(e)=>setImageFile(e.target.files[0])} type='file' />
                                          </div>
                                    </div>

                                    <div className="d-flex justify-content-end mt-4">
                                          <button className="btn btn-secondary rounded-pill" onClick={()=>navigate('/profile')}>Close</button>
                                    </div>
                              
                              </div>
                        </div>
                  </div>

            </div>
      </>
};

//NB: if any of these conditions are changed, then
//remember to change the rules displayed to users on Register and ResetPwd frontend pages


function checkUsernameValidity(username)
{
      //removing all white spaces from username for doing validation properly
      username=username.replace(/ +/g, '');

      //username must contain minimum 5 characters
      //can contain maximum 30 characters
      //can contain only letters (all spaces are already removed)
      if( username.length>=5 && username.length<=30 && /^[A-Za-z]+$/.test(username) ) return true;
      else return false;
};

function checkEmailValidity(email)
{
      //trimming email address for doing validation properly
      email=email.trim();

      //email address must have min 1 char before '@' symbol and min 1 char before and after '.' symbol and '\S' means anything except white space
      //it can only contain upper and lowercase letters, digits, underscore, period, dash and '@' symbol
      if( /^\S+@\S+\.\S+$/.test(email) && /^[A-Za-z0-9_.@-]+$/.test(email) ) return true;
      else return false;
};

function checkPwdValidity(password)
{
      //password must contain minimum 8 characters
      //can contain maximum 30 characters
      if( password.length>=8 && password.length<=30 ) return true;
      else return false;
};


module.exports={checkUsernameValidity, checkEmailValidity, checkPwdValidity};
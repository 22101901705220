//libraries
import {Link, useNavigate} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faHouse, faEnvelope, faUser, faCoins, faRightToBracket, faArrowsRotate} from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import Ripples from 'react-ripples';

//css
import '../styles/Navbar.css';

//js
import {navbarColorLearner, navbarColorDarkLearner, navbarColorTeacher, navbarColorDarkTeacher} from '../assets/navbarColors.js';


export function Navbar({selectedNavItem, user, setUser, socket, chatBoxes, sentDeal, setSentDeal, acceptedSentDeal, setAcceptedSentDeal, acceptedReceivedDeal, setAcceptedReceivedDeal})
{
      const [isSocketConnected, setIsSocketConnected]=useState(socket.connected);
      const [unseenChatBoxesCount, setUnseenChatBoxesCount]=useState(0);
      const navigate=useNavigate();


      useEffect(()=>
      {
            if(chatBoxes===false || user===false) return;

            let unseenChatBoxesCounter=0

            for(let i=0; i<=chatBoxes.length-1; i++) 
            {
                  let chatBox=chatBoxes[i];
                  let inboxUser=chatBox.inboxUser;
                  let chats=chatBox.chats;
                  let lastChat=chats[chats.length-1];

                  if(lastChat.toId===user.id_users && lastChat.isSeen===0)
                  {
                        unseenChatBoxesCounter++;
                  };
            };

            setUnseenChatBoxesCount(unseenChatBoxesCounter);
      },
      [chatBoxes, user.id_users]);


      //showing warning if socket connection lost
      useEffect(()=>
      {
            if(socket===false) return;

            socket.on('connect', ()=> 
            {
                  setIsSocketConnected(socket.connected);
            });

            socket.on('disconnect', ()=> 
            {
                  setIsSocketConnected(socket.connected);
            });
            
            return ()=>
            {
                  socket.off('connect');
                  socket.off('disconnect');
            };
      },
      [socket]);


      function getNavbarBgColor()
      {
            if(user.teacherMode==='on') return navbarColorTeacher;
            else return navbarColorLearner;
      };


      function getSelectedNavItemBgColor(navItem)
      {
            if(user.teacherMode==='on')
            {
                  if(navItem===selectedNavItem) return navbarColorDarkTeacher;
            }

            else
            {
                  if(navItem===selectedNavItem) return navbarColorDarkLearner;
            };
      };


      function cancelSentDealBtn()
      {
            socket.emit('send_cancel_sentDeal', {}, function(cbData)
            {
                  if(cbData.status===false) return alert(cbData.msg);

                  if(cbData.status===true) setSentDeal(false);
            });
      };


      function finishReceivedDeal()
      {
            socket.emit('send_finish_receivedDeal', {}, function(cbData)
            {
                  if(cbData.status===false) alert(cbData.msg);

                  if(cbData.status===true) 
                  {
                        alert('You finished the deal. Please give a review.');

                        let fromId=cbData.fromId;

                        setAcceptedReceivedDeal(false);

                        navigate(`/details?ui=${fromId}`);
                  };
            });
      };


      function finishSentDeal()
      {
            socket.emit('send_finish_SentDeal', {}, function(cbData)
            {
                  if(cbData.status===false) alert(cbData.msg);

                  if(cbData.status===true) 
                  {
                        let payment=cbData.payment;

                        setAcceptedSentDeal(false);


                        //increasing coins
                        let userClone=JSON.parse(JSON.stringify(user));
                        userClone.coins_teacher=userClone.coins_teacher+payment;
                        setUser(userClone);
                  };
            });
      };


      return<>
            <div className="sticky-top d-flex flex-column justify-content-center" style={{backgroundColor: getNavbarBgColor()}}>
                  <div className="d-flex">

                        <Ripples className="myNavItem flex-fill d-flex justify-content-center c-pointer rounded" style={{backgroundColor: getSelectedNavItemBgColor('home')}} onClick={()=>navigate('/home')}>
                              <div className={'navItemTitleBox'+(selectedNavItem==='home' ? ' text-primary' : '')}>
                                    <i className="fa-regular fa-house"></i>
                                    <span className='navItemTitle'> Home</span>
                              </div>
                        </Ripples>

                        <Ripples className="myNavItem flex-fill d-flex justify-content-center c-pointer rounded" style={{backgroundColor: getSelectedNavItemBgColor('messages')}} onClick={()=>navigate('/messages')}>
                              <div className={'navItemTitleBox'+(selectedNavItem==='messages' ? ' text-primary' : '')}>
                                    <i className="fa-regular fa-envelope"></i>
                                    <span className='navItemTitle'> Messages{user!==false && ':'}</span>
                                    { (user!==false) && <span className={unseenChatBoxesCount===0 ? '' : 'font-weight-bold'}> {unseenChatBoxesCount}</span> }
                              </div>
                        </Ripples>

                        <Ripples className="myNavItem flex-fill d-flex justify-content-center c-pointer rounded" style={{backgroundColor: getSelectedNavItemBgColor('profile')}} onClick={()=>navigate('/profile')}>
                              <div className={'navItemTitleBox'+(selectedNavItem==='profile' ? ' text-primary' : '')}>
                                    <i className="fa-regular fa-user"></i>
                                    <span className='navItemTitle'> Profile</span>
                              </div>
                        </Ripples>

                        <Ripples className="myNavItem flex-fill d-flex justify-content-center c-pointer rounded" style={{backgroundColor: getSelectedNavItemBgColor('coins')}} onClick={()=>navigate('/coins')}>
                              <div className={'navItemTitleBox'+(selectedNavItem==='coins' ? ' text-primary' : '')}>
                                    <i className={ 'fa-regular' + ( user.teacherMode==='on' ? ' fa-circle-t' : ' fa-circle-s' ) }></i>
                                    <span className='navItemTitle'> Coins{user!==false && ':'}</span>
                                    { (user!==false) && <span> {user.teacherMode==='on' ? user.coins_teacher : user.coins_learner}</span> }
                              </div>
                        </Ripples>

                        { (user===false) &&
                        <Ripples className="myNavItem flex-fill d-flex justify-content-center c-pointer rounded" style={{backgroundColor: getSelectedNavItemBgColor('login')}} onClick={()=>navigate('/login')}>
                              <div className='navItemTitleBox'>
                                    <i className="fa-regular fa-right-to-bracket"></i>
                                    <span className='navItemTitle'> Login</span>
                              </div>
                        </Ripples>}

                  </div>

                  { (user!==false && isSocketConnected===false) &&
                        <div className='bg-danger text-white text-center p-2' onClick={()=>window.location.reload()}><b>Connection Lost. Click Here to Refresh</b> <FontAwesomeIcon icon={faArrowsRotate} /></div>
                  }

                  { (sentDeal!==false) &&
                        <div className='bg-warning text-dark d-flex justify-content-between font-weight-bold px-2' style={{paddingTop: '2px', paddingBottom: '2px'}}>
                              <div className='d-flex flex-fill justify-content-center align-items-center'>
                                    <div className='mr-1'>{sentDeal}</div>
                              </div>
                              <div className='d-flex align-items-center'>
                                    <div className='btn btn-secondary rounded-pill' onClick={cancelSentDealBtn}>Cancel</div>
                              </div>
                        </div>
                  }

                  { (acceptedReceivedDeal!==false) &&
                        <div className='bg-success text-light d-flex justify-content-between font-weight-bold px-2' style={{paddingTop: '2px', paddingBottom: '2px'}}>
                              <div className='d-flex flex-fill justify-content-center align-items-center'>
                                    <div className='mr-1'>{acceptedReceivedDeal}</div>
                              </div>
                              <div className='d-flex align-items-center'>
                                    <div className='btn btn-light rounded-pill' onClick={finishReceivedDeal}>Finish</div>
                              </div>
                        </div>
                  }

                  { (acceptedSentDeal!==false) &&
                        <div className='bg-success text-light d-flex justify-content-between font-weight-bold px-2' style={{paddingTop: '2px', paddingBottom: '2px'}}>
                              <div className='d-flex flex-fill justify-content-center align-items-center'>
                                    <div className='mr-1'>{acceptedSentDeal}</div>
                              </div>
                              <div className='d-flex align-items-center'>
                                    <div className='btn btn-light rounded-pill' onClick={finishSentDeal}>Finish</div>
                              </div>
                        </div>
                  }
            </div>
      </>
};

//libraries
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEye, faCheck} from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';

//components
import { CaptchaBox } from '../components/CaptchaBox';
import { MsgBox } from '../components/MsgBox';
import { Prompt } from '../components/Prompt';

//css
import '../styles/loggedOutPages.css';

//js
import {getDevId} from '../assets/sec/getDevId';
import {usernameRules, emailRules, passwordRules} from '../assets/userCredentialRules';

//common
import {checkUsernameValidity, checkEmailValidity, checkPwdValidity} from '../common/checkInputValidity';


export function Register({setUser})
{
      const [miniMsg, setMiniMsg]=useState('');
      const [isMiniMsgVisible, setIsMiniMsgVisible]=useState(false);
      const [fadeIn, setFadeIn]=useState(false);
      
      const [captchaToken, setCaptchaToken]=useState('');
      const [isCaptchaVisible, setIsCaptchaVisible]=useState(false);
      
      const [msgBoxMsg, setMsgBoxMsg]=useState('');
      const [isMsgBoxVisible, setIsMsgBoxVisible]=useState(false);
      
      const [promptMsg, setPromptMsg]=useState('');
      const [regCode, setRegCode]=useState('');
      const [isPromptVisible, setIsPromptVisible]=useState(false);

      const [username, setUsername]=useState('');
      const [isUsernameValid, setIsUsernameValid]=useState(false);

      const [email, setEmail]=useState('');
      const [isEmailValid, setIsEmailValid]=useState(false);

      const [password, setPassword]=useState('');
      const [isPwdValid, setIsPwdValid]=useState(false);
      const [isPwdVisible, setIsPwdVisible]=useState(false);

      const navigate=useNavigate();


      useEffect
      (
            ()=>
            {
                  document.querySelector('body').style.backgroundColor='white';
                  return ()=> document.querySelector('body').style.backgroundColor='rgb(250, 250, 250)';
            },
            []
      );

      useEffect
      (
            ()=>
            {
                  setIsUsernameValid( checkUsernameValidity(username) );
                  setIsEmailValid( checkEmailValidity(email) );
                  setIsPwdValid( checkPwdValidity(password) );
            },
            [username, email, password]
      );

      function showMiniMsg(msg)
      {
            setFadeIn(true);

            setIsMiniMsgVisible(true);
            setMiniMsg(msg);

            setTimeout(() => {
                  setFadeIn(false);
            }, 1000);
      };

      async function directLogin()
      {
            let res=await axios.post('/getUser', {});
            setUser(res.data.user);

            navigate('/');
      };

      function showMsg(msg)
      {
            setMsgBoxMsg(msg);
            setIsMsgBoxVisible(true);
      };

      function showPrompt(msg)
      {
            setPromptMsg(msg);
            setIsPromptVisible(true);
      };
      
      async function handleRegister()
      {
            showMiniMsg("<b>Loading message. Please wait...</b>");
            if(navigator.onLine===false) return showMiniMsg('<b>You are offline</b>');

            let res=await axios.post('/register',
            {
                  username: username,
                  email: email,
                  password: password,
                  captchaToken: captchaToken,
                  regCode: regCode,
                  devId: await getDevId()
            });


            showMiniMsg('');
            setIsPromptVisible(false);
            setIsCaptchaVisible(false);

            if(res.data.showMsgIn==='miniMsg') showMiniMsg(res.data.msg);

            if(res.data.showMsgIn==='prompt') showPrompt(res.data.msg);

            if(res.data.showMsgIn==='captcha') setIsCaptchaVisible(true);
            
            if(res.data.willLogin===true) showMsg('<b>Congratulations! You have been Registered successfully.</b><br>Logging in.');
      };


      return<>
            { isCaptchaVisible && <CaptchaBox setIsCaptchaVisible={setIsCaptchaVisible} handleDone={handleRegister} setCaptchaToken={setCaptchaToken} /> }

            { isPromptVisible && <Prompt setIsPromptVisible={setIsPromptVisible} handleDone={handleRegister} promptMsg={promptMsg} mySetPromptInput={setRegCode} /> }

            { isMsgBoxVisible && <MsgBox setIsMsgBoxVisible={setIsMsgBoxVisible} handleDone={directLogin} msgBoxMsg={msgBoxMsg} /> }


            <div className="container-fluid" style={ (isCaptchaVisible || isPromptVisible || isMsgBoxVisible) ? {opacity: '0.1', pointerEvents: 'none'} : {} }>

                  <div className="outerFormBox row justify-content-center my-sm-5">
                        <div style={{width: '400px'}} className="px-1">

                              <div className="text-center mb-3">
                                    <img className="img-fluid" width="90%" src={require("../assets/logo.jpg")} alt="MyXAcademy.com" />
                              </div>

                              <div className={"px-3 py-1 mt-3 alert alert-primary "+ (fadeIn ? " animate__animated animate__fadeIn " : ' ') + (isMiniMsgVisible ? ' ' : ' invisible ') } dangerouslySetInnerHTML={{ __html: miniMsg}}></div>


                              <div className={miniMsg!=='' ? 'moveFormBox' : ''}>
                                    <form onSubmit={ (e) => e.preventDefault() } className="rounded py-3 px-2" style={{border: '2px solid rgba(128, 128, 128, 0.715)'}}>
                                                
                                          <div className="input-group mb-2">
                                                <input onFocus={()=>showMiniMsg(usernameRules)} onBlur={()=>setIsMiniMsgVisible(false)} onChange={ (e) => setUsername(e.target.value) } className="form-control rounded" type="text" name="savedInputUsername" placeholder="Username" />
                                                <div className="input-group-append">
                                                      <div className="d-flex align-items-center pl-1" style={{fontSize: '20px'}}>
                                                            <div className={"text-success" + (isUsernameValid ? '' : ' invisible')}>
                                                                  <FontAwesomeIcon icon={faCheck} />
                                                            </div>
                                                      </div>
                                                </div>
                                          </div>

                                          <div className="input-group mb-2">
                                                <input onFocus={()=>showMiniMsg(emailRules)} onBlur={()=>setIsMiniMsgVisible(false)} onChange={ (e) => setEmail(e.target.value) } className="form-control rounded" type="email" name="savedInputEmail" placeholder="Email Address" />
                                                <div className="input-group-append">
                                                      <div className="d-flex align-items-center pl-1" style={{fontSize: '20px'}}>
                                                            <div className={"text-success" + (isEmailValid ? '' : ' invisible')}>
                                                                  <FontAwesomeIcon icon={faCheck} /> 
                                                            </div>
                                                      </div>
                                                </div>
                                          </div>
                                          
                                          <div className="input-group mb-2">
                                                <input onFocus={()=>showMiniMsg(passwordRules)} onBlur={()=>setIsMiniMsgVisible(false)} onChange={ (e) => setPassword(e.target.value) } name="savedInputPwd" type={isPwdVisible ? 'text' : 'password'} className="form-control" placeholder="New Password" />
                                                <div className="input-group-append">
                                                      <div onClick={ (e) => {if(isPwdVisible) setIsPwdVisible(false); else setIsPwdVisible(true); } } className="rounded-right d-flex align-items-center px-1" style={{fontSize: '20px', border: '1px solid #d0cdcd', backgroundColor: isPwdVisible && 'black', color: isPwdVisible && 'white'}}>
                                                            <FontAwesomeIcon icon={faEye} />
                                                      </div>
                                                      <div className="d-flex align-items-center pl-1" style={{fontSize: '20px'}}>
                                                            <div className={"text-success" + (isPwdValid ? '' : ' invisible')}>
                                                                  <FontAwesomeIcon icon={faCheck} />
                                                            </div>
                                                      </div>
                                                </div>
                                          </div>

                                          <button onClick={handleRegister} type="submit" className="btn btn-block btn-primary mt-4"><b>Register</b></button>

                                    </form>

                                    <Link to='/login' className='text-dark mt-4 d-inline-block'>Already have an account? <b><u>Login</u></b></Link>                                    
                              </div>
                        
                        </div>
                  </div>

            </div>
      </>
};

//libraries
import axios from 'axios';

//js
import {getDevId} from './getDevId';


//write 'props' as parameter for this function
export function refreshTokenClient(props)
{
      let currTime=new Date()/1000; //in sec

      let cookieTime=getCookie('time');

      //if 'time' cookie is not set, then we are stopping here
      if( isNaN(cookieTime) )
      {
            console.log('NaN a giye stopped');
            return;
      };

      let timePassedInSec=currTime-cookieTime;

      printFormattedTime(timePassedInSec);

      //changeHere set refreshing time here
      //setting to 9 mins 30 secs
      let refreshTokenAfter=(9*60)+30; //in seconds

      let timeLeft=refreshTokenAfter-timePassedInSec;

      let shouldRefreshPg=false;

      if(timeLeft<0)
      {
            timeLeft=0;
            shouldRefreshPg=true;
      };

      const myTimeout=setTimeout(async ()=>
      {
            let res=await axios.post( '/refreshToken', { devId: await getDevId() } );

            if(res.data.isRefreshed===true)
            {
                  if(shouldRefreshPg)
                  {
                        let res=await axios.post('/getUser', {});
                        props.setUser(res.data.user);
                  };
                  
                  //below code block condition was newly added
                  if(props.willStop===true) 
                  {
                        console.log('Refreshed token and stopping');
                        clearTimeout(myTimeout);
                        return;
                  };
                  
                  console.log('Refreshed token');

                  refreshTokenClient(props);
            }

            else
            {
                  //if refreshToken is called to run recursively, then socket will be false
                  //if refreshToken is called to run once, then socket will be undefined
                  //so checking both the conditions

                  if(props.socket!==false && props.socket!==undefined) props.socket.disconnect();
                  props.setUser(false);
                  props.setSocket(false);
            };
      }
      ,
      //converting from milliseconds to seconds
      timeLeft*1000);
};


function printFormattedTime(timePassedInSec)
{
      let s=timePassedInSec;
      let min=parseInt(s/60);
      let sec=parseInt( (s/60-min) * 60 );
      let extraZero=(sec<10) ? '0' : '' ;
      console.log(`---------- ${min}:${extraZero}${sec} ----------`);
};


function getCookie(name) 
{
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if(parts.length === 2) return parts.pop().split(';').shift();
};

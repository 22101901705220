export function Prompt({promptMsg, setIsPromptVisible, handleDone, mySetPromptInput})
{
      function donePrompt()
      {
            setIsPromptVisible(false);
            handleDone();
            mySetPromptInput('');
      };


      return<>
            <div className="d-flex justify-content-center mx-3">
                  <div className="animate__animated animate__fadeInDown     shadow position-fixed rounded p-3" style={{top: '24%', zIndex: '1', backgroundColor: 'white', border: '2px solid black', '--animate-duration' : '0.3s', maxWidth: '400px'}}>
                        <div className="mb-3" dangerouslySetInnerHTML={{__html: promptMsg}}></div>
                        <input onChange={(e)=>mySetPromptInput(e.target.value)} className="form-control" type="text" placeholder="Type here..." />
                        <div className="mt-4">
                              <button className="btn btn-primary mr-2" onClick={donePrompt}>Done</button>
                              {/* <button className="btn btn-danger" onclick="hidePrompt();">Cancel</button> */}
                        </div>
                  </div>
            </div>
      </>
};

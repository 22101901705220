//libraries
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircle, faStar, faSpinner} from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Ripples from 'react-ripples';

//js
import { env } from '../assets/env';


export function OfferCard({user, offer, activeUserIdArr, chatBoxes, setChatBoxes, toggleShowTopics=()=>{}, profileImgHash, offerImgHash, renderActiveStatus, dateNow})
{
      const navigate=useNavigate();


      async function handleCardMessageBtn(id_users)
      {
            //if not logged in
            if(user===false) return alert('Please login to send message');


            //if sending to self
            if(id_users===user.id_users) return alert('You cannot message yourself');


            //if had previously chatted with this user
            for(let i=0; i<=chatBoxes.length-1; i++)
            {
                  if(chatBoxes[i].inboxUser.id_users===id_users) return navigate(`/inbox?ui=${id_users}`);
            };


            //if never chatted before, so creating new element and adding it in chatBoxes array
            let chatBoxesClone=JSON.parse(JSON.stringify(chatBoxes));

            let res=await axios.post('/getUserFromId', {id_users: id_users});

            chatBoxesClone.push
            ({
                  inboxUser: res.data.user,
                  chats: [{shouldHide: true}]
            });

            setChatBoxes(chatBoxesClone);

            return navigate(`/inbox?ui=${id_users}`);
      };


      return<>
            <Ripples className='d-flex justify-content-center offerCardBody'>
                  <div key={offer.id_offers} className="card card-home border-0 rounded-lg" onClick={()=>toggleShowTopics(offer.id_offers)}>

                        <div className="cardTop px-sm-0">
                              <div className="cardImgBox d-flex flex-column justify-content-center">
                                    <img className="cardImg w-100 h-100" src={env.backendBaseUrl+`/offerImages/${offer.id_offers}.jpg` + (offer.user.id_users===user.id_users ? `?${offerImgHash}` : `?${dateNow}`) } onError={e=>e.target.src=require('../assets/defaultImage.jpg')} style={{objectFit: 'cover'}} />
                              </div>

                              <div className="my-1 mt-sm-2 mb-sm-0 d-flex align-items-center">
                                    <img className='mx-1 rounded-circle' src={`${env.backendBaseUrl}/profileImages/${offer.user.id_users}.jpg` + (offer.user.id_users===user.id_users ? `?${profileImgHash}` : `?${dateNow}`) } onError={(e)=>e.target.src=require('../assets/defaultImage.jpg')} style={{height: '30px', width: '30px', objectFit: 'cover'}} />

                                    <div className='text-nowrap text-truncate mr-1 font-weight-bold'>{offer.user.username}</div>
                                    
                                    <div>{ renderActiveStatus(offer.user.id_users) }</div>
                              </div>
                        </div>

                        <div className="card-body d-flex flex-column justify-content-between">
                              {/* showing rating in case of mobile screen */}
                              <div className='ml-sm-2 text-warning d-flex align-items-center d-sm-none'>
                                    <FontAwesomeIcon icon={faStar} size='xs' />
                                    <span className='ml-1 font-weight-bold' style={{fontSize: 'large'}}>{offer.avgRating}</span> 
                                    <span className="text-muted ml-1">({offer.reviewsArr.length})</span>
                              </div>

                              <div className="card-text" style={{height: '49px', overflowY: 'auto', overflow: 'hidden'}}>
                                    I will {offer.offer_description}
                              </div>

                              {/* showing rating in case of pc screen */}
                              <div className='ml-sm-2 text-warning d-none d-sm-flex align-items-center'>
                                    <FontAwesomeIcon icon={faStar} size='sm' />
                                    <span className='mx-1 font-weight-bold' style={{fontSize: 'large'}}>{offer.avgRating}</span> 
                                    <span className="text-muted">({offer.reviewsArr.length})</span>
                              </div>
                              
                              <div className="cardBtnBox d-flex justify-content-around">
                                    <Ripples onClick={()=>handleCardMessageBtn(offer.user.id_users)} id="cardMsgBtn" className="text-primary c-pointer px-2 py-1 rounded-pill">Message</Ripples>
                                    <Ripples onClick={()=>navigate(`/details?ui=${offer.user.id_users}`)} id="cardDetailsBtn" className="text-info c-pointer px-2 py-1 rounded-pill">Details</Ripples>
                              </div>
                        </div>

                  </div>
            </Ripples>
      </>
};

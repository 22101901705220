//libraries
import axios from "axios";


export function Coins({user, setUser})
{
      async function addMoreCoinsBtn()
      {
            if(user===false) return alert('Please login');

            let coinsToBuy=document.getElementById('coinsToBuy').value;

            let res=await axios.post('/addMoreCoins', {coinsToBuy});

            if(res.data.status===false) alert(res.data.msg);

            if(res.data.status===true) window.location.href=res.data.url;
      };


      async function saveBkashWalletNum()
      {
            if(user===false) return alert('Please login');

            let bw_number=prompt('Enter your bKash wallet/account number:');
            if(bw_number===null) return;

            let res=await axios.post('/add-bw_number', {bw_number});

            if(res.data.status===true)
            {
                  alert('Your number has been saved ✅');

                  let res2=await axios.post('/getUser', {});
                  setUser(res2.data.user);
            };
            
            if(res.data.status===false) alert(res.data.msg);
      };


      async function deleteBkashWalletNum()
      {
            if(user===false) return alert('Please login');

            let confirmDeletion=window.confirm('Are you sure you want to delete your number? You will not receive payment.');
            if(confirmDeletion===false) return;

            let res=await axios.post('/delete-bw_number', {});

            if(res.data.status===true)
            {
                  alert('Your number has been deleted');

                  let res2=await axios.post('/getUser', {});
                  setUser(res2.data.user);
            };
      };


      function formatBWNumber(n)
      {
            let p1=n.slice(0, 3);
            let p2=n.slice(3, 7);
            let p3=n.slice(7);

            return p1+'-'+p2+'-'+p3;
      };


      return<>
            <h2 className='text-center mt-4 mb-5'>Coins</h2>

            <div className="d-flex justify-content-center">
                  <div className="table" style={{maxWidth: '500px'}}>

                        { (user===false || user.teacherMode==='off') &&
                              <div className="mb-5">
                                    <div className="bg-white rounded py-3 px-sm-3 px-2 mb-4">
                                          <h4 className="text-warning">
                                                <i className="fa-solid fa-circle-s mr-2" />
                                                <b className="mr-2">Coin balance:</b>
                                                {user.coins_learner}
                                          </h4>
                                    </div>

                                    <div className="bg-white rounded py-3 px-sm-3 px-2">
                                          <div className="mb-3 ml-1" style={{fontSize: 'large'}}>
                                                <span className="mr-2">Add more coins</span>
                                                <i className="fa-duotone fa-circle-question" onClick={()=>alert('Enter number of coins you want to add')} />
                                          </div>
                                          
                                          <div className="mb-2">
                                                <input id="coinsToBuy" className="form-control rounded-pill px-3" placeholder="Number of coins..." type="number" />
                                          </div>
                                          
                                          <div className="d-flex justify-content-end">
                                                <div className="btn btn-success rounded-pill px-4 py-1" onClick={addMoreCoinsBtn}>Add</div>
                                          </div>
                                    </div>
                              </div>
                        }


                        { (user===false || user.teacherMode==='on') &&
                              <div className="mb-5">
                                    <div className="bg-white rounded py-3 px-sm-3 px-2 mb-4">
                                          <h4 className="text-primary">
                                                <i className="fa-solid fa-circle-t mr-2" />
                                                <b className="mr-2">Coins earned:</b>
                                                {user.coins_teacher}
                                          </h4>
                                    </div>

                                    <div className="bg-white rounded py-3 px-sm-3 px-2">
                                          <div className="mb-4 ml-1" style={{fontSize: 'large'}}>
                                                <span className="mr-2">Your bKash wallet number:</span>
                                                <i className="fa-duotone fa-circle-question" onClick={()=>alert('You will receive payment in this bKash account based on the number of coins you have earned. We will not share your number with anyone.')} />
                                          </div>

                                          { (user.bw_number) ?
                                                <div className="mb-5 ml-1 text-center">
                                                      <div className="mr-2 mb-1 font-weight-bold" style={{fontSize: 'larger'}}>{ formatBWNumber(user.bw_number) }</div>
                                                      <div className="text-muted">(please make sure that this number is correct)</div>
                                                </div>
                                          :
                                                <div className="mb-5 ml-1 text-center text-muted">
                                                      <span className="mr-2">(no number added)</span>
                                                </div>
                                          }
                                          
                                          <div className="d-flex justify-content-between align-items-end">
                                                <div className="text-danger" onClick={deleteBkashWalletNum}>Delete number</div>
                                                <div className="btn btn-success rounded-pill px-4 py-1" onClick={saveBkashWalletNum}>Change number</div>
                                          </div>
                                    </div>
                                    
                              </div>
                        }

                  </div>
            </div>

      </>
};

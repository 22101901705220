export function getAvgRating(reviewsArr)
{
      if(reviewsArr.length===0) return 0;

      let sumRating=0;

      for(let i=0; i<=reviewsArr.length-1; i++)
      {
            let rev=reviewsArr[i];
            sumRating=sumRating+rev.rating;
      };

      let avgRating=sumRating/(reviewsArr.length);
      avgRating=avgRating.toFixed(1);
      avgRating=Number(avgRating);

      return avgRating;
};
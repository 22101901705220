//libraries
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEye} from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import 'animate.css';

//components
import { CaptchaBox } from '../components/CaptchaBox';

//css
import '../styles/loggedOutPages.css';

//js
import {getDevId} from '../assets/sec/getDevId';


export function Login({setUser, deviceType})
{
      const [isPwdVisible, setIsPwdVisible]=useState(false);

      const [email, setEmail]=useState('');
      const [password, setPassword]=useState('');
      const [captchaToken, setCaptchaToken]=useState('');

      const [miniMsg, setMiniMsg]=useState('');
      const [fadeIn, setFadeIn]=useState(false);
      
      const [isCaptchaVisible, setIsCaptchaVisible]=useState(false);

      const navigate=useNavigate();

      
      useEffect(()=>
      {
            document.querySelector('body').style.backgroundColor='white';
            return ()=> document.querySelector('body').style.backgroundColor='rgb(250, 250, 250)';
      },
      []);


      function showMiniMsg(msg)
      {
            setFadeIn(true);

            setMiniMsg(msg);

            setTimeout(() => {
                  setFadeIn(false);
            }, 1000);
      };


      async function handleLogin()
      {
            showMiniMsg("<b>Loading message. Please wait...</b>");
            if(navigator.onLine===false) showMiniMsg('<b>You are offline</b>');

            let res=await axios.post('/login',
            {
                  email: email,
                  password: password,
                  captchaToken: captchaToken,
                  devId: await getDevId()
            });
            
            showMiniMsg('');
            setIsCaptchaVisible(false);

            if(res.data.showMsgIn==='miniMsg') showMiniMsg(res.data.msg);
            
            if(res.data.showMsgIn==='captcha') 
            {
                  if(navigator.onLine===false) return showMiniMsg('<b>You are offline</b>');

                  setIsCaptchaVisible(true);
            };
            
            if(res.data.willLogin===true)
            {
                  let res=await axios.post('/getUser', {});
			setUser(res.data.user);

                  navigate('/');
            };
      };


      async function joinWithGoogleBtn()
      {
            const res=await axios.post('/join-with-google', {});

            if(deviceType=='computer')
            {
                  window.location.href=res.data.url;
            };

            if(deviceType=='webview')
            {
                  //sending data to ReactNative
                  let data={status: 'join-with-google', googleOauthUrl: res.data.url};
                  window.ReactNativeWebView.postMessage(JSON.stringify(data));
            };
      };


      return<>
            { isCaptchaVisible && <CaptchaBox setIsCaptchaVisible={setIsCaptchaVisible} setCaptchaToken={setCaptchaToken} handleDone={handleLogin} /> }
            
            <div className="container-fluid" style={{opacity: isCaptchaVisible && '0.1', pointerEvents: isCaptchaVisible && 'none'}}>

                  <div className="outerFormBox row justify-content-center my-sm-5">
                        <div style={{width: '400px'}} className="px-1">
                              
                              <div className="text-center mb-3">
                                    <img className="img-fluid" width="90%" src={require("../assets/logo.jpg")} alt="MyXAcademy.com" />
                              </div>

                              <div className={"px-3 py-1 mt-3 alert alert-primary "+ (fadeIn ? " animate__animated animate__fadeIn " : '') + (miniMsg==='' ? ' invisible ' : '') } dangerouslySetInnerHTML={{ __html: miniMsg}}></div>


                              <div className={miniMsg!=='' ? 'moveFormBox' : ''}>
                                    <form onSubmit={ (e) => e.preventDefault() } className="rounded py-3 px-2" style={{border: '2px solid rgba(128, 128, 128, 0.715)'}}>
                                          <input onChange={ (e) => setEmail(e.target.value) } name="savedInputEmail" type="email" className="form-control mb-3" placeholder="Email Address" />

                                          <div className="input-group mb-3">
                                                <input onChange={ (e) => setPassword(e.target.value) } name="savedInputPwd" type={isPwdVisible ? 'text' : 'password'} className="form-control" placeholder="Password" />
                                                <div className="input-group-append">
                                                      <div onClick={ (e) => {if(isPwdVisible) setIsPwdVisible(false); else setIsPwdVisible(true); } } className="rounded-right d-flex align-items-center px-1" style={{fontSize: '20px', border: '1px solid #d0cdcd', backgroundColor: isPwdVisible && 'black', color: isPwdVisible && 'white'}}>
                                                            <FontAwesomeIcon icon={faEye} />
                                                      </div>
                                                </div>
                                          </div>

                                          <button onClick={handleLogin} type="submit" className="btn btn-block btn-primary mt-4"><b>Login</b></button>
                                    </form>

                                    <Link to='/resetPwd' className='mt-4 d-inline-block text-muted'>Forgotten Password?</Link>
                                    
                                    <div className='mt-sm-4 mt-5 d-flex justify-content-center'>
                                          <div>
                                                <div className="mb-3 text-center d-flex">
                                                      <button className='btn btn-dark flex-fill rounded-pill color-changing-border' onClick={joinWithGoogleBtn}><i className="fa-brands fa-google" /> Join with Google</button>
                                                </div>
                                                <div className="mb-3 text-center d-flex">
                                                      <div className="btn btn-success flex-fill rounded-pill" onClick={()=>navigate('/register')}>Create Account</div>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>

                  </div>

            </div>
      </>
};

const env_development=
{
      backendBaseUrl: 'http://localhost:3001',
      // backendBaseUrl: 'http://192.168.0.165:3001', //for webview
};


const env_production=
{
      backendBaseUrl: 'https://myxacademy.com',
};


let env;
if(process.env.NODE_ENV==='production') env=env_production;
else env=env_development;
export {env};

import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';


serviceWorkerRegistration.register();
const root=ReactDOM.createRoot(document.getElementById('root'));


root.render(
      <BrowserRouter>
            <App />
      </BrowserRouter>
);


//erasing console.logs
if(process.env.NODE_ENV==='production') console.log=function(){};

//converting datetime from ISO format to custom format
export function formatDateTime(dati)
{
      if(dati===undefined) return;
      
      //converting to Bd time by adding 6 hours
      dati=new Date(new Date(dati).getTime()+6*60*60*1000).toISOString();

      let monthNum=dati.slice(5, 7);
      let date=dati.slice(8, 10);
      let year=dati.slice(0, 4);
      let time=dati.slice(11, 16);


      //converting time from 24hr format to 12hr format
      function formatTime(time)
      {
            time=time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

            if(time.length>1)
            {
                  time=time.slice(1);
                  time[5]=+time[0]<12 ? ' AM' : ' PM';
                  time[0]=+time[0]%12 || 12;
            };

            return time.join('');
      };


      //converting month from number to words
      function formatMonth(n)
      {
            n=Number(n);

            if(n==1) return 'Jan'
            if(n==2) return 'Feb'
            if(n==3) return 'Mar'
            if(n==4) return 'Apr'
            if(n==5) return 'May'
            if(n==6) return 'Jun'
            if(n==7) return 'Jul'
            if(n==8) return 'Aug'
            if(n==9) return 'Sep'
            if(n==10) return 'Oct'
            if(n==11) return 'Nov'
            if(n==12) return 'Dec'

            else return 'Invalid month';
      };


      if(Number(year)===new Date().getFullYear()) return `${ formatMonth(monthNum) } ${date}, ${ formatTime(time) }`;
      return `${ formatMonth(monthNum) } ${date}, ${year}, ${ formatTime(time) }`;
};

import { useEffect } from "react";


export function GoogleOauthWebviewHandle()
{
      useEffect(()=>
      {
            //changeHere todo the app's deep link below:
      
            let appBaseUrl='myxacademy://';
            // let appBaseUrl='exp+go://expo-development-client/?url=http%3A%2F%2F192.168.0.165%3A8081';
            // let appBaseUrl='exp://192.168.0.165:8081';

            window.location.href=`${appBaseUrl}?googleOauthUrl=${window.location.href}`;
      },
      []);
};

import { useEffect } from 'react';


export function DloadApp()
{
      useEffect(()=>
      {
            //changing background color
            document.querySelector('body').style.backgroundColor='white';
            return ()=> document.querySelector('body').style.backgroundColor='rgb(250, 250, 250)';
      },
      [])


      return<>
            <div class="d-flex flex-column justify-content-center align-items-center text-center" style={{marginTop: '12rem'}}>
                  <img className='img-fluid px-3' src={require('../assets/logo.jpg')} />
                  <h4 className='mb-5'>is now available on Google Play</h4>
                  <button onClick={()=>window.location.href='https://play.google.com/store/apps/details?id=com.shadmanfating.GO'} className='btn btn-primary px-5 py-3 font-weight-bold' style={{fontSize: '20px'}}>Click here!</button>
            </div>
      </>;
};

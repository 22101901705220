//libraries
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";

//js
import { getDevId } from "../assets/sec/getDevId";


export function GoogleOauth({setUser})
{
      const [searchParams, setSearchParams]=useSearchParams();
      const navigate=useNavigate();
      

      useEffect(()=>
      {
            let code=searchParams.get('code');
            let error=searchParams.get('error');
            // let scope=searchParams.get('scope');
            // let authuser=searchParams.get('authuser');
            // let prompt=searchParams.get('prompt');
            
      
            (async function()
            {
                  let res=await axios.post('/google-oauth', {code, error, devId: await getDevId()});
            
                  if(res.data.status===false) return navigate('/login');
                  
                  if(res.data.status===true)
                  {
                        let res=await axios.post('/getUser', {});
                        setUser(res.data.user);

                        navigate('/');
                  };
            })();
      },
      []);


      return<>
            <div className="text-center mx-3 mx-sm-0" style={{marginTop: '16rem'}}>
                  <div style={{fontSize: 'larger'}}>
                        <i className="fa-duotone fa-spinner-third fa-spin mr-1" />Loading...
                  </div>
            </div>
      </>;
};

export function OfferTopics({offer, selectedTopicId})
{
      return<>
            <div className="offerDetails d-flex flex-column justify-content-between mb-2">
                  <div>
                        <div className="text-center font-weight-bold">{offer.class_.className}</div>
                        <div className="text-center font-weight-bold">{offer.department.deptName}</div>
                        <div className="mt-2" style={{overflowY: 'auto', maxHeight: '200px'}}>
                              {offer.topics.map((topic, i)=>
                                    <div key={topic.id_topics} className='py-1 px-3 bg-white rounded mb-1' style={ topic.id_topics==selectedTopicId ? {border: '2px solid #6C757D'} : {} }>
                                          {topic.topicName}
                                    </div>
                              )}
                        </div>
                  </div>
            </div>
      </>
};

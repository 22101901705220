export function OfflinePg()
{
      function offlineLogout()
      {
            document.cookie="time=1707869956;";

            alert('Logging out');
      };


      return<>
            <div className="text-center mt-5">
                  <h1 className="mb-5">You are offline</h1>
                  <div className="d-flex justify-content-center align-items-center">
                        <div className="text-danger font-weight-bold mr-5 c-pointer" onClick={offlineLogout}><u>Logout</u></div>
                        <button className="btn btn-primary rounded-pill" onClick={()=>window.location.reload()}>Refresh</button>
                  </div>
            </div>
      </>
};

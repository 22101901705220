//libraries
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";


export function BkashPayment({setUser})
{
      const navigate=useNavigate();
      const [searchParams, setSearchParams]=useSearchParams();
      const [resMsg, setResMsg]=useState(false);
      const [statusMsg, setStatusMsg]=useState('');
      

      useEffect(()=>
      {
            let paymentID=searchParams.get('paymentID');
            let status=searchParams.get('status');


            (async function()
            {
                  let res=await axios.post('/executePayment', {paymentID, status});

                  if(res.data.status===false) 
                  {
                        setResMsg(res.data.msg);
                        if(res.data.statusMsg) setStatusMsg(res.data.statusMsg);
                  }
                  
                  if(res.data.status===true) 
                  {
                        let res=await axios.post('/getUser', {});
			      setUser(res.data.user);

                        setResMsg('payment successful');
                  };
            })();
      },
      []);


      return<>
            <h1 className="text-center mt-5">{(resMsg===false) ? <><i className="fa-duotone fa-spinner-third fa-spin mr-1" />Loading...</> : <>{resMsg}</>}</h1>
            <h1 className="text-center">{statusMsg}</h1>
            <div className="text-center mt-5">
                  <div className="btn btn-dark rounded-pill mt-4" onClick={()=>navigate('/coins')}>
                  <i className="fa-solid fa-arrow-left mr-2" />Go Back
                  </div>
            </div>
      </>;
};

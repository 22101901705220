export function MsgBox({msgBoxMsg, setIsMsgBoxVisible, handleDone})
{
      function doneMsgBox()
      {
            setIsMsgBoxVisible(false);
            handleDone();
      };


      return<>
            <div className="d-flex justify-content-center mx-3">
                  <div className="animate__animated animate__fadeInDown     shadow position-fixed rounded p-3" style={{top: '24%', zIndex: '1', backgroundColor: 'white', border: '2px solid black', '--animate-duration' : '0.3s', maxWidth: '400px'}}>
                        <div className="mb-3" dangerouslySetInnerHTML={{__html: msgBoxMsg}}></div>
                        <div className="mt-4">
                              <button className="btn btn-primary" onClick={doneMsgBox}>Ok</button>
                        </div>
                  </div>
            </div>
      </>
};
